import { IValidator } from '@netvision/lib-react-table'

export type IDecode<T> = (v: unknown) => T | undefined
export type ITransform<T1, T2> = (v: T1) => T2 | undefined

export function decoder<T>(validator: IValidator<T>): IDecode<T>

export function decoder<T1, T2>(
  validator: IValidator<T1>,
  transform: ITransform<T1, T2>
): IDecode<T2>

export function decoder<T1, T2>(validator: IValidator<T1>, transform?: (v: T1) => T2 | undefined) {
  return (v: unknown): T1 | T2 | undefined => {
    if (validator(v)) {
      if (typeof transform === 'function') {
        return transform?.(v) ?? undefined
      } else {
        return v
      }
    } else {
      return undefined
    }
  }
}
