/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { IIdCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { CopyField } from './helpers/CopyField'
import React, { useMemo } from 'react'
import { decodeString } from './helpers/utils'

export function IdCell<T extends {}, K extends string>(props: ICellProps<T, K, IIdCell>) {
  const { rowData, field } = props
  const id = useMemo(() => decodeString(rowData, field, ''), [rowData, field])
  return !id ? null : (
    <CopyField css={$css} value={id} toShort={(id) => `${id.slice(0, 4)}..${id.slice(-4)}`} />
  )
}

const $css = css`
  & > input {
    font-family: monospace, monospace;
    width: calc(110rem / var(--bfs));
  }
`
