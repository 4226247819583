import store from '../store'
import { useEffect, useState } from 'react'
import { IUserInfo } from '@netvision/lib-api-repo/dist/src/types'

const isUserInfo = (userInfo: IUserInfo | Record<string, any>): userInfo is IUserInfo =>
  Boolean(userInfo?.userId)

export const useUserData = () => {
  const [userInfo, setUserInfo] = useState<Partial<IUserInfo> | null>(null)

  useEffect(() => {
    if (!('getUserInfo' in store.api)) {
      console.warn('getUserInfo does not implements in api-repo')
      return setUserInfo({})
    }

    store.api
      .getUserInfo()
      .then((res) => {
        if (isUserInfo(res)) setUserInfo(res)
      })
      .catch(console.error)
  }, [])

  return userInfo
}
