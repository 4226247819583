import { ISearchFilterMeta } from '../../models/IFilterMeta'
import type { ISearchFilter } from '@netvision/lib-react-table/dist/src/filterTypes'
import { IWidgetLocale } from '../../locale/locale'
import { optionsFromEntities, searchByField, searchById } from './utils'

export const transformSearch = (
  filterMeta: ISearchFilterMeta,
  locale: IWidgetLocale
): ISearchFilter => {
  const {
    entityType,
    maxEntries = 1,
    minLength = 1,
    queryField = 'title',
    limit = 20,
    labelField = 'title'
  } = filterMeta
  return {
    type: 'search',
    field: filterMeta.field,
    placeholder: locale.search,
    maxEntries,
    minLength,
    getOptionsByValues: (values: Array<string | number>) =>
      searchById(
        entityType,
        values.map((v) => v.toString())
      ).then(optionsFromEntities(labelField)),
    searchOptions: (query: string) =>
      searchByField(entityType, query, queryField, limit).then(optionsFromEntities(labelField))
  }
}
