import React, { FC, useEffect, useMemo } from 'react'
import { Main } from './components/Main'
import { useMetadata } from './hooks/useMetadata'
import { LocaleProvider } from './locale/LocaleProvider'
import { FetchEntityProvider } from './hooks/useEntity'
import { ApiRepositoryProvider } from './hooks/useApiRepository'
import { ITab, IWidgetProps } from './IWidgetProps'
import { usePermittedScopes } from './hooks/usePermissions'
import { useLocationProp } from './hooks/useLocationProp'
import { history } from '@netvision/lib-history'
import { debounce } from 'lodash-es'
import { LoadingObsProvider, useUpdateLoading } from './providers/LoadingObsProvider'

export const Root: FC<IWidgetProps> = (widgetProps: IWidgetProps) => {
  const { baseUrl = '', tabs = [], lib = { name: 'lib-api' } } = widgetProps.props || {}
  return (
    <LoadingObsProvider>
      <LocaleProvider>
        <FetchEntityProvider>
          <ApiRepositoryProvider lib={lib}>
            <PermittedScopesAdapter tabs={tabs} baseUrl={baseUrl} />
          </ApiRepositoryProvider>
        </FetchEntityProvider>
      </LocaleProvider>
    </LoadingObsProvider>
  )
}

const PermittedScopesAdapter: FC<{ tabs: ITab[]; baseUrl: string }> = ({ tabs, baseUrl }) => {
  const initialScopeKey = 'Read'
  const permittedScopes = usePermittedScopes(
    useMemo(() => tabs.map((t) => `${initialScopeKey}${t.entityType}`), [tabs]),
    initialScopeKey
  )

  const filteredTabs = useMemo(() => {
    return tabs
      .filter((t) => permittedScopes.get(initialScopeKey)?.has(`Read${t.entityType}`))
      .map((t) => ({
        ...t,
        link: baseUrl + t.link
      }))
  }, [tabs, baseUrl, permittedScopes.get(initialScopeKey)])

  if (filteredTabs.length === 0) {
    return null
  }

  return <MetadataAdapter tabs={filteredTabs} />
}

const MetadataAdapter: FC<{ tabs: ITab[] }> = ({ tabs }) => {
  const pathname = useLocationProp('pathname')
  const tabIndex = tabs.findIndex((t) => t.link === pathname)
  const updateRoute = useMemo(
    () =>
      debounce((value: string, signal: AbortSignal) => {
        if (!signal.aborted) {
          history.push(value)
        }
      }),
    // eslint-disable-next-line
    []
  )
  useEffect(() => {
    if (tabIndex === -1) {
      const abr = new AbortController()
      updateRoute(tabs[0].link, abr.signal)
      return () => {
        abr.abort()
      }
    }
    return undefined
  }, [updateRoute, tabs, tabIndex])
  const { entityType } = tabIndex === -1 ? tabs[0] : tabs[tabIndex]
  const entityTypes = useMemo(() => tabs.map((t) => t.entityType), [tabs])
  const [loading, metadataMap, loadingErrorMessage] = useMetadata(entityTypes, 'ru')
  useUpdateLoading(loading)
  const metadata = metadataMap.get(entityType)
  const localizedTabs = useMemo(
    () =>
      tabs.map((t) => {
        return {
          title: metadataMap.get(t.entityType)?.$locale?.typeTitlePlural || '...',
          ...t
        }
      }),
    [tabs, metadataMap]
  )
  const { title } = tabIndex === -1 ? localizedTabs[0] : localizedTabs[tabIndex]
  return (
    <Main
      title={title}
      entityType={entityType}
      tabs={localizedTabs}
      metadata={metadata}
      loadingErrorMessage={loadingErrorMessage}
    />
  )
}
