import { IRangeFilterMeta } from '../../models/IFilterMeta'
import { IWidgetLocale } from '../../locale/locale'
import type { IRangeFilter } from '@netvision/lib-react-table/dist/src/filterTypes'

export const transformRange = (
  filterMeta: IRangeFilterMeta,
  locale: IWidgetLocale
): IRangeFilter => {
  return {
    ...filterMeta,
    noneText: locale.noneText,
    anyText: locale.anyText
  }
}
