import { IIconCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { decodeIcon } from './helpers/utils'
import React, { useMemo } from 'react'

export function IconCell<T extends {}, K extends string>(props: ICellProps<T, K, IIconCell>) {
  const { rowData, field } = props
  const icon = useMemo(() => decodeIcon(rowData, field, null), [rowData, field])
  return icon === null ? null : (
    <i
      title={icon.iconClass}
      className={`mdi mdi-24px ${icon.iconClass}`}
      style={icon.color ? { color: icon.color } : undefined}
    />
  )
}
