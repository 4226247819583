import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { useLocale } from '../locale/LocaleProvider'
import { Dialog } from 'primereact/dialog'
import { IEntity } from '@netvision/lib-api-repo'
import { useToastRef } from '../hooks/useToastRef'
import { useApiRepository } from '../hooks/useApiRepository'

export const DeleteModal: FC<{
  entities: IEntity[]
  entityType?: string
  onClose: (deleted?: Set<string | number>) => void
}> = ({ entities, entityType, onClose: _onHide }) => {
  const locale = useLocale()
  const [visible, onHide] = useDialogVisible(_onHide)
  const [loading, setLoading] = useState(false)
  const { api } = useApiRepository()
  const footer = (
    <Fragment>
      <Button
        disabled={loading}
        className={'p-button-danger'}
        label={locale.delete}
        onClick={() => {
          setLoading(true)
          const promises = entities.map(async (entity: any) => (
            await api.deleteEntity({
              id: entity.id,
              type: entity.type || entityType
            })
              .then(() => [entity, null])
              .catch((err: unknown) => {
                console.error(err)
                return [entity, err]
              })
          ))

          Promise.all(promises)
            .then((res) => {
              const fulfilled = res.filter(([, err]) => err === null)
              if (fulfilled.length > 0) {
                toastRef.current?.show({
                  severity: 'success',
                  summary: locale.success,
                  detail: locale.deleteDialog.success.replace('{{}}', fulfilled.length.toString())
                })
              }
              const rejected = res.filter(([, err]) => err instanceof Error)
              if (rejected.length > 0) {
                toastRef.current?.show({
                  severity: 'error',
                  summary: locale.error,
                  detail: locale.deleteDialog.error.replace('{{}}', fulfilled.length.toString())
                })
              }
              // @ts-ignore
              onHide(new Set(fulfilled.map(([en]) => en.id)))
            })
            .finally(() => {
              setLoading(false)
            })


          // setLoading(true)
          // const promises = entities.map(async (en) => {
          //   return await deleteEntity({ id: en.id, type: en.type })
          //     .then(() => [en, null])
          //     .catch((err: unknown) => {
          //       console.error(err)
          //       return [en, err]
          //     })
          // })
          // Promise.all(promises)
          //   .then((res) => {
          //     const fulfilled = res.filter(([, err]) => err === null)
          //     if (fulfilled.length > 0) {
          //       toastRef.current?.show({
          //         severity: 'success',
          //         summary: locale.success,
          //         detail: locale.deleteDialog.success.replace('{{}}', fulfilled.length.toString())
          //       })
          //     }
          //     const rejected = res.filter(([, err]) => err instanceof Error)
          //     if (rejected.length > 0) {
          //       toastRef.current?.show({
          //         severity: 'error',
          //         summary: locale.error,
          //         detail: locale.deleteDialog.error.replace('{{}}', fulfilled.length.toString())
          //       })
          //     }
          //     // @ts-ignore
          //     onHide(new Set(fulfilled.map(([en]) => en.id)))
          //   })
          //   .finally(() => {
          //     setLoading(false)
          //   })
        }}
      />
      <Button
        disabled={loading}
        className={'p-button-secondary'}
        label={locale.cancel}
        onClick={() => onHide()}
      />
    </Fragment>
  )
  const toastRef = useToastRef()
  return (
    <Dialog header={locale.deleteDialog.header} onHide={() => {}} visible={visible} footer={footer}>
      <div style={{ padding: '0 var(--form-padding)' }}>
        {locale.deleteDialog.message.replace('{{}}', entities.length.toString())}
      </div>
    </Dialog>
  )
}

export const useDialogVisible = <T extends any[]>(
  onHide: (...args: T) => void
): [boolean, (...args: T) => void] => {
  const [visible, setVisible] = useState(false)
  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
    setVisible(true)
    return () => {
      mountedRef.current = false
    }
  }, [])
  return [
    visible,
    useCallback(
      (...args: T) => {
        setVisible(false)
        setTimeout(() => {
          if (mountedRef.current) {
            onHide(...args)
          }
        }, 200)
      },
      [onHide, mountedRef]
    )
  ]
}
