import { isArray, isNumber, isShape, isString, or } from '@netvision/lib-react-table'
import { IJoinParameter } from '../../../models/ICellCommon'
import { get } from 'lodash-es'
import { decoder, IDecode } from '../../../utils/decoder'

const adaptDecode = <T>(decode: IDecode<T>) => {
  return <D>(obj: {}, prop: string, defaults: D) => {
    return decode(get(obj, prop)) ?? defaults
  }
}

export const decodeString = adaptDecode(decoder(isString))

export const decodeStringArray = adaptDecode(decoder(isArray(isString)))

export const decodeObject = adaptDecode(decoder(isShape({})))

export const decodeDateTime = adaptDecode(
  decoder(or(isString, isNumber), (v) => {
    const date = new Date(v)
    if (!isNaN(date.getTime())) {
      return date
    } else {
      return undefined
    }
  })
)

export const decodeUrl = adaptDecode(
  decoder(isString, (v) => {
    let href = decodeURI(v)
    if (href.includes('%')) {
      href = decodeURIComponent(v)
    }
    let url
    try {
      let str = href
      const index = str.indexOf('://')
      if (index !== -1) {
        str = `http${str.slice(index)}`
      }
      url = new URL(str)
    } catch (e) {}
    return { href, url }
  })
)

export const decodeIcon = adaptDecode(
  decoder(
    isShape({
      iconClass: isString,
      color: isString
    })
  )
)

export const applyJoin = (object: {}, params: IJoinParameter) => {
  const separator = params.separator ?? ', '
  return params.fields
    .map((f) => decodeString(object, f, null))
    .filter((e) => isString(e) && e !== '')
    .join(separator)
}
