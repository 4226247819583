import React, { useMemo } from 'react'
import { IDateTimeCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { decodeDateTime } from './helpers/utils'

export function DateTimeCell<T extends {[key: string]: string}, K extends string>(
  props: ICellProps<T, K, IDateTimeCell>
) {
  const { rowData, field, description: { warningPeriodInDays } } = props  
  const cellStyle = { color: 'var(--text-color)' }

  if (warningPeriodInDays) {
    const daysDiff = (new Date(rowData[field]).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
    if (daysDiff < warningPeriodInDays && daysDiff >= -1) {
      cellStyle.color = 'var(--warning-color)'
    } else if (daysDiff < warningPeriodInDays && daysDiff < 1) {
      cellStyle.color = 'var(--error-color)'
    }
  }
  const dateTime = useMemo(() => decodeDateTime(rowData, field, null), [rowData, field])
  return !dateTime ? null : <span style={cellStyle}>{dateTime.toLocaleString()}</span>
}
