import React from 'react'
import { KeyOf } from '../../models/utils'
import {
  ICell,
  isBooleanCell,
  isDateTimeCell,
  isIconCell,
  isIdCell,
  isImageCell,
  isNumberCell,
  isObjectCell,
  isPasswordCell,
  isRefArrayCell,
  isRefCell,
  isTextCell,
  isUrlCell
} from '../../models/ICell'
import { TextCell } from './TextCell'
import { RefArrayCell, RefCell } from './RefCell'
import { IdCell } from './IdCell'
import { PasswordCell } from './PasswordCell'
import { UrlCell } from './UrlCell'
import { ObjectCell } from './ObjectCell'
import { IconCell } from './IconCell'
import { BooleanCell } from './BooleanCell'
import { NumberCell } from './NumberCell'
import { ImageCell } from './ImageCell'
import { DateTimeCell } from './DateTimeCell'

export type ICellProps<T extends {}, K extends string, C extends ICell> = {
  rowData: T
  field: K
  description: C
}

export const selectCell = <T extends {}, K extends string, C extends ICell>(
  rowData: T,
  field: K,
  description: C
) => {
  let element
  if (isTextCell(description)) {
    element = <TextCell rowData={rowData} field={field} description={description} />
  } else if (isNumberCell(description)) {
    element = <NumberCell rowData={rowData} field={field} description={description} />
  } else if (isBooleanCell(description)) {
    element = <BooleanCell rowData={rowData} field={field} description={description} />
  } else if (isRefCell(description)) {
    element = <RefCell rowData={rowData} field={field} description={description} />
  } else if (isRefArrayCell(description)) {
    element = <RefArrayCell rowData={rowData} field={field} description={description} />
  } else if (isIdCell(description)) {
    element = <IdCell rowData={rowData} field={field} description={description} />
  } else if (isPasswordCell(description)) {
    element = <PasswordCell rowData={rowData} field={field} description={description} />
  } else if (isUrlCell(description)) {
    element = <UrlCell rowData={rowData} field={field} description={description} />
  } else if (isObjectCell(description)) {
    element = <ObjectCell rowData={rowData} field={field} description={description} />
  } else if (isIconCell(description)) {
    element = <IconCell rowData={rowData} field={field} description={description} />
  } else if (isImageCell(description)) {
    element = <ImageCell rowData={rowData} field={field} description={description} />
  } else if (isDateTimeCell(description)) {
    element = <DateTimeCell rowData={rowData} field={field} description={description} />
  } else {
    element = <TextCell rowData={rowData} field={field} description={{ type: 'text' }} />
  }
  return element
}
