const deleteDialog = {
  header: 'Удалить',
  message: 'Удалить сущности ({{}})?',
  success: 'Сущности ({{}}) успешно удалены',
  error: 'Не удалось удалить сущности ({{}})'
}

export const defaultLocale = {
  copy: 'Скопировать',
  search: 'Поиск',
  noneText: 'Отсутствует',
  anyText: 'Любое значение',
  add: 'Добавить',
  delete: 'Удалить',
  cancel: 'Отмена',
  selectedTemplate: 'Выбрано - {{}}',
  success: 'Успешно',
  error: 'Ошибка',
  deleteDialog
}

export type IWidgetLocale = typeof defaultLocale
