import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import { Root } from './Root'
import { setPublicPath } from 'systemjs-webpack-interop'

setPublicPath(PACKAGE_NAME)

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // https://reactjs.org/docs/error-boundaries.html
    console.error(err, info, props)
    return React.createElement('div')
  }
})
