import type { ISearchFilter } from '@netvision/lib-react-table/dist/src/filterTypes'
import { optionsFromEntities, searchById } from './utils'

export const transformId = (entityType: string): ISearchFilter => {
  return {
    type: 'search',
    placeholder: 'Поиск',
    minLength: 1,
    maxEntries: 1,
    getOptionsByValues: (values: Array<string | number>) =>
      searchById(
        entityType,
        values.map((v) => v.toString())
      ).then(optionsFromEntities('id')),
    searchOptions: (query: string) =>
      searchById(entityType, [query]).then(optionsFromEntities('id'))
  }
}
