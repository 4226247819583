import type { ITextFilter } from '@netvision/lib-react-table/dist/src/filterTypes'
import { ITextFilterMeta } from '../../models/IFilterMeta'
import { IWidgetLocale } from '../../locale/locale'

export const transformText = (filterMeta: ITextFilterMeta, locale: IWidgetLocale): ITextFilter => {
  return {
    ...filterMeta,
    placeholder: locale.search
  }
}
