import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { simpleObservable, ISimpleObservable } from '@netvision/lib-react-table'

const ctxObs = createContext<ISimpleObservable<boolean>>(null!)
const ctxCounter = createContext<{
  increment: () => void
  decrement: () => void
}>(null!)

export const LoadingObsProvider: FC = ({ children }) => {
  const [loadingObs] = useState(() => simpleObservable(false))
  const loadingCounter = useMemo(() => {
    let counter = 0
    return {
      increment: () => {
        counter = counter + 1
        if (counter > 0) {
          loadingObs.set(true)
        }
      },
      decrement: () => {
        counter = Math.max(0, counter - 1)
        if (counter === 0) {
          loadingObs.set(false)
        }
      }
    }
  }, [loadingObs])
  return (
    <ctxCounter.Provider value={loadingCounter}>
      <ctxObs.Provider value={loadingObs}>{children}</ctxObs.Provider>
    </ctxCounter.Provider>
  )
}

export const useLoadingObs = () => {
  return useContext(ctxObs)
}

export const useUpdateLoading = (loading: boolean) => {
  const loadingCounter = useContext(ctxCounter)
  useEffect(() => {
    if (loading) {
      loadingCounter.increment()
      return () => {
        loadingCounter.decrement()
      }
    }
    return undefined
  }, [loading, loadingCounter])
}
