import React, {createContext, FC, MutableRefObject, useContext, useRef} from 'react';
import {Toast, ToastProps} from 'primereact/toast';

const ctx = createContext<MutableRefObject<Toast | null>>(null!);
export const useToastRef = () => {
  return useContext(ctx);
};

export const ToastProvider: FC<ToastProps> = ({children, ...rest}) => {
  const ref = useRef<Toast | null>(null);
  return (
    <ctx.Provider value={ref}>
      {children}
      <Toast ref={ref} {...rest} />
  </ctx.Provider>
);
};
