import { observable, action } from 'mobx'
import { repositoryGetter, TUnionRepo, TLibProp } from '@netvision/lib-api-repo'

class Store {
  @observable api = {} as TUnionRepo
  @observable lib?: TLibProp

  @action setApiRepository(lib?: TLibProp) {
    this.api = repositoryGetter(lib)
    this.lib = lib
  }
}

export default new Store()
