import { IFilterDescription } from '@netvision/lib-react-table'
import { IMetadata } from '../models/IMetadata'
import { IColumnDescription } from '../models/IViewTable'
import { IWidgetLocale } from '../locale/locale'
import { isNotNull } from '../utils/isNotNull'
import { selectFilter } from './filters'

export const metadataToFilterDescription = (
  entityType: string,
  metadata: IMetadata,
  locale: IWidgetLocale
): IFilterDescription<string> => {
  const entries = Object.entries(metadata.viewTable.columns)
    .map((entry) => {
      const [key, value] = entry as [string, IColumnDescription]
      const transformed = selectFilter(entityType, key, value, locale)
      if (transformed) {
        return [key, transformed] as const
      }
      return null
    })
    .filter(isNotNull)
  return Object.fromEntries(entries) as IFilterDescription<string>
}
