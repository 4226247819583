import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useApiRepository } from './useApiRepository'
// import { createBatchQueue } from '../utils/batchQueue'

export const usePermittedScopes = <K extends string>(scopes: K[], entityType: string): Map<string, Set<string>> => {
  const [permitted, setPermitted] = useState<Map<string, Set<string>>>(useContext(permissionsCtx))
  const { api } = useApiRepository()

  const permissionSetter = (permission: K[]) => {
    setPermitted(new Map(
      permitted.set(
        entityType,
        new Set(permission)
      )
    ))
  }

  const isPermissionsResponseDiff = (res: string[], permissions: Set<string> | undefined) => {
    if (res.length === permissions?.size) {
      return !res.every((item) => permissions.has(item))
    }

    return true
  }

  const permissionsScoping = () => {
    api.getPermissions(scopes)
      .then((res) => {
        if (isPermissionsResponseDiff(res, permitted.get(entityType))) {
          permissionSetter(res as K[])
        }
      })
      .catch((error: Error) => console.error(error))
  }

  useEffect(() => {
    // Initially allow all to prevent UI from blocking
    if (permitted.size === 0) {
      permissionSetter(scopes)
    } else {
      permissionsScoping()
    }
  }, [scopes])

  return permitted
}
/**
 * To do: complete hook and context provider 
 */
// export const useEntityPermissionScopes = <K extends string>(id: string): Set<K> => {
//   const getScopes = useContext(ctx)
//   const [scopes, setRes] = useState<Set<K>>(aSet)

//   useEffect(() => {
//     getScopes(id)
//       .then(setRes)
//       .catch(() => setRes(aSet))
//   }, [id, getScopes])

//   return scopes
// }

// export const PermissionsProvider: FC = ({ children }) => {
//   const [cache] = useState(() => new Map<string, Set<string>>())
//   const batchQ = useMemo(
//     () =>
//       createBatchQueue<string, Set<string>>(100, (inputs) => {
//         // import 'getUserPermissionsByIdsMap' from 'lib-api' and include in 'permissions' method in LibApiRepository
//         return getUserPermissionsByIdsMap(clear(inputs.map((v) => v.args))).then((map) => {
//           return inputs.map(({ args, $id }) => {
//             return {
//               $id,
//               result: new Set(map.get(args))
//             }
//           })
//         })
//       }),
//     []
//   )

//   const getScopes = useCallback(
//     (id: string) => {
//       const scopes = cache.get(id)
//       if (scopes) {
//         return Promise.resolve(scopes)
//       } else {
//         return new Promise<Set<string>>((resolve) =>
//           batchQ.add(
//             id,
//             () => false,
//             (scopes) => {
//               cache.set(id, scopes)
//               resolve(scopes)
//             }
//           )
//         )
//       }
//     },
//     [batchQ, cache]
//   )

//   return <ctx.Provider value={getScopes}>{children}</ctx.Provider>
// }

// type Ctx<K> = (id: string) => Promise<Set<K>>

// const ctx = createContext<Ctx<any>>(null!)
const permissionsCtx = createContext(new Map<string, Set<string>>())

// const aSet = <T,>() => new Set<T>()
// const clear = (arr: string[]) => Array.from(new Set(arr))
