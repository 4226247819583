import React, { createContext, FC, useContext } from 'react'
import { defaultLocale, IWidgetLocale } from './locale'

const ctx = createContext(defaultLocale)

export const LocaleProvider: FC<{ value?: IWidgetLocale }> = ({
  children,
  value = defaultLocale
}) => {
  return <ctx.Provider value={value}>{children}</ctx.Provider>
}

export const useLocale = () => {
  return useContext(ctx)
}
