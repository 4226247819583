import React, { useMemo } from 'react'
import { ICellProps } from './selectCell'
import { IObjectCell } from '../../models/ICell'
import { applyJoin, decodeObject } from './helpers/utils'
import { JSONField } from './helpers/JSONField'
import { $textWrap } from './helpers/styles'

export function ObjectCell<T extends {}, K extends string>(props: ICellProps<T, K, IObjectCell>) {
  const { rowData, field, description } = props
  const object = useMemo(() => decodeObject(rowData, field, null), [rowData, field])
  if (object === null) {
    return null
  }
  const { join } = description
  if (join) {
    const text = applyJoin(object, join)
    return !text ? null : <span css={$textWrap}>{text}</span>
  }
  return <JSONField object={object} />
}
