/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useMemo } from 'react'
import { IRefArrayCell, IRefCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { applyJoin, decodeString, decodeStringArray } from './helpers/utils'
import { useEntities, useEntity } from '../../hooks/useEntity'
import { isNotNull } from '../../utils/isNotNull'
import { ImageField } from './helpers/ImageField'
import { $textWrap } from './helpers/styles'
import { useUpdateLoading } from '../../providers/LoadingObsProvider'

export function RefCell<T extends {}, K extends string>(props: ICellProps<T, K, IRefCell>) {
  const { rowData, description } = props
  const id = useMemo(() => decodeString(rowData, description.idField, ''), [rowData, description])
  const type = useMemo(() => {
    if (description.typeStatic) {
      return description.typeStatic
    } else if (description.typeField) {
      return decodeString(rowData, description.typeField, '')
    } else {
      return ''
    }
  }, [rowData, description])
  const [loading, error, entity] = useEntity(type, id)
  useUpdateLoading(loading)
  const text = useMemo(() => {
    if (entity === null) {
      return ''
    } else {
      const { join = { fields: ['title'] } } = description
      return applyJoin(entity, join)
    }
  }, [entity, description])
  if (loading) {
    return null
  }
  return text.length === 0 ? (
    <span>{'-'}</span>
  ) : (
    <span css={[$refCss, $textWrap]}>
      {entity && description.picture ? (
        <ImageField object={entity} field={description.picture} />
      ) : null}
      {text}
    </span>
  )
}

const $refCss = css`
  display: flex;
  align-items: center;
  > img {
    margin-right: var(--spacer-xs);
  }
`

export function RefArrayCell<T extends {}, K extends string>(
  props: ICellProps<T, K, IRefArrayCell>
) {
  const { rowData, description } = props
  const ids = useMemo(() => decodeStringArray(rowData, description.idsField, [] as string[]), [
    rowData,
    description
  ])
  const [, entities] = useEntities(description.typeStatic, ids)
  const text: string = useMemo(() => {
    if (ids.length === 0) {
      return ''
    }
    const { join = { fields: ['title'] } } = description
    return ids
      .map((id) => entities.get(id) ?? null)
      .filter(isNotNull)
      .map((entity) => applyJoin(entity, join))
      .join('; ')
  }, [entities, ids, description])
  return text.length === 0 ? (
    <span>{'-'}</span>
  ) : (
    <span css={[$refArrayCss, $textWrap]}>
      [ {text.length > 120 ? `${text.slice(0, 120)}...` : text} ]
    </span>
  )
}

const $refArrayCss = css`
  & {
    font-size: calc(12rem / var(--bfs));
  }
`
