import {
  isArray,
  isExact,
  isNumber,
  isShape,
  isString,
  isUndefined,
  or,
  refine
} from '@netvision/lib-react-table'
import { isDefNumber } from './utils'

type IOption<T> = { label: string; value: T }

export type IFilterMeta =
  | ITextFilterMeta
  | ITimeFilterMeta
  | IEnumFilterMeta
  | IEnumRefFilterMeta
  | IRangeFilterMeta
  | ISearchFilterMeta
  | IAutoCompleteFilterMeta
  | IIdFilterMeta

export interface ITextFilterMeta {
  type: 'text'
  // defaults to key, could be icon.iconClass
  field?: string
}

export const isTextFilterMeta = isShape<ITextFilterMeta>({
  type: isExact('text'),
  field: or(isUndefined, isString)
})

export interface ITimeFilterMeta {
  type: 'dateTime'
  field?: string
  min?: number | 'now'
  max?: number | 'now'
}

const isTimeLimit = or(isDefNumber, refine(isString, isExact<'now'>('now')))

export const isTimeFilterMeta = refine(
  isShape<ITimeFilterMeta>({
    type: isExact('dateTime'),
    field: or(isUndefined, isString),
    min: or(isUndefined, isTimeLimit),
    max: or(isUndefined, isTimeLimit)
  }),
  (v) => (isNumber(v.min) && isNumber(v.max) ? checkMinMax(v.min, v.max) : true)
)

export interface IEnumFilterMeta {
  type: 'enum'
  field?: string
  options: IOption<string | number>[]
}

export const isEnumFilterMeta = isShape<IEnumFilterMeta>({
  type: isExact('enum'),
  field: or(isUndefined, isString),
  options: isArray(
    isShape<IOption<string | number>>({ label: isString, value: or(isString, isNumber) })
  )
})

export interface IIdFilterMeta {
  type: 'id'
  field?: string
}

export const isIdFilterMeta = isShape<IIdFilterMeta>({
  type: isExact('id'),
  field: or(isUndefined, isString)
})

export interface IEnumRefFilterMeta {
  type: 'enumRef'
  field?: string
  entityType: string
  labelField?: string | 'title'
  orderBy?: string
}

export const isEnumRefFilterMeta = isShape<IEnumRefFilterMeta>({
  type: isExact('enumRef'),
  field: or(isUndefined, isString),
  entityType: isString,
  labelField: or(isUndefined, isString),
  orderBy: or(isUndefined, isString)
})

export interface IRangeFilterMeta {
  type: 'range'
  field?: string
  min: number
  max: number
  step?: number
  template?: string
  toFixed?: number
  noneText?: string
  anyText?: string
}

export const isRangeFilterMeta = refine(
  isShape<IRangeFilterMeta>({
    type: isExact('range'),
    field: or(isUndefined, isString),
    min: isDefNumber,
    max: isDefNumber,
    step: or(
      isUndefined,
      refine(isDefNumber, (v) => v > 0)
    ),
    template: or(
      isUndefined,
      refine(isString, (v) => v.includes('{{1}}') && v.includes('{{2}}'))
    ),
    toFixed: or(
      isUndefined,
      refine(isDefNumber, (v) => v >= 0)
    ),
    noneText: or(isUndefined, isString),
    anyText: or(isUndefined, isString)
  }),
  (v) => checkMinMax(v.min, v.max)
)

export interface ISearchFilterMeta {
  type: 'searchRef'
  field?: string
  entityType: string
  labelField?: string | 'title'
  queryField?: string | 'title'
  limit?: number | 20
  maxEntries?: number | 1
  minLength?: number | 1
}

export const isSearchFilterMeta = isShape<ISearchFilterMeta>({
  type: isExact('searchRef'),
  field: or(isUndefined, isString),
  entityType: isString,
  labelField: or(isUndefined, isString),
  queryField: or(isUndefined, isString),
  limit: or(
    isUndefined,
    refine(isDefNumber, (v) => v > 0)
  ),
  maxEntries: or(
    isUndefined,
    refine(isDefNumber, (v) => v > 0)
  ),
  minLength: or(
    isUndefined,
    refine(isDefNumber, (v) => v >= 1)
  )
})

export interface IAutoCompleteFilterMeta {
  type: 'autocompleteRef'
  field?: string
  entityType: string
  queryField?: string | 'title'
  labelField?: string | 'title'
  minLength?: number | 1
  limit?: number | 20
}

export const isAutoCompleteFilterMeta = isShape<IAutoCompleteFilterMeta>({
  type: isExact('autocompleteRef'),
  field: or(isUndefined, isString),
  entityType: isString,
  minLength: or(
    isUndefined,
    refine(isDefNumber, (v) => v >= 1)
  ),
  labelField: or(isUndefined, isString),
  limit: or(
    isUndefined,
    refine(isDefNumber, (v) => v > 0)
  )
})

export const checkMinMax = (min?: number, max?: number) => {
  min = typeof min === 'undefined' ? -Infinity : min
  max = typeof max === 'undefined' ? Infinity : max
  return min <= max
}
