import { isArray, isShape, isString, isUndefined, IValidator, or } from '@netvision/lib-react-table'

export type IJoinParameter = {
  fields: string[]
  separator?: string
}

export const isJoinParameter: IValidator<IJoinParameter> = isShape({
  fields: isArray(isString),
  separator: or(isString, isUndefined)
})
