import { IEntity, IListPayload } from '@netvision/lib-api-repo'
import { get, isObject } from 'lodash-es'
import store from '../../store'

export const load = <E extends IEntity>(params: IListPayload): Promise<E[]> => 
  store.api.getEntitiesList<E>({ ...params })
    .then(({ results }) => (results.every(isObject) ? results : []))
    .catch(() => [])

export const join = (s: string | string[], separator: string) =>
  Array.isArray(s) ? s.join(separator) : s

export const optionsFromEntities = (labelField: string) => (entities: IEntity[]) => {
  return entities.map((e: any) => ({ value: e.id, label: get(e, labelField) }))
}

export const searchByField = <E extends IEntity>(
  type: string,
  query: string,
  field: string,
  limit: number
): Promise<E[]> =>
  load({
    limiter: {
      type: join(type, ','),
      orderBy: field,
      limit
    },
    filter: {
      q: query.length > 0 ? [
        {
          key: field,
          value: query,
          operator: '~=',
          insensitify: true,
          sanitize: true
        }
      ] : undefined
    }
  })

export const searchById = <E extends IEntity>(
  type: IEntity['type'],
  id: string | string[]
) => {
  id = join(id, ',')
  return id.length > 0 ?
    load({
      limiter: {
        id,
        type: join(type, ',')
      }
    }) :
    Promise.resolve([])
}
